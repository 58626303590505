<template>
    <div class="sidebar" :class="{ sideToggle: sidebarOpen }">
        <div class="nav-toggle" @click="toggleSide">
            <el-icon v-if="!sidebarOpen">
                <DArrowRight />
            </el-icon>

            <el-icon v-else>
                <DArrowLeft />
            </el-icon>
        </div>

        <el-menu :collapse="!sidebarOpen">
            <template v-for="(pillar, index) in sidebarLinks" :key="index">
                <!-- WOMP (above) -->
                <!-- eslint-disable-next-line vue/valid-v-for -->
                <el-sub-menu
                    v-show="pillarState[pillar.label]"
                    :class="{ active: pillarActive(pillar) }"
                    :index="index"
                >
                    <template #title>
                        <i class="icon-" :class="pillar.icon"></i>&nbsp;
                        <span>{{ pillar.label }}</span>
                        <span v-if="pillar.trademark">&#8482;</span>
                    </template>
                    <el-menu-item-group>
                        <template #title>
                            <span class="title" v-if="!sidebarOpen">
                                {{ pillar.label }}
                                <span v-if="pillar.trademark">&#8482;</span>
                            </span>
                        </template>
                        <template
                            v-for="(link, indexG) in pillar.children"
                            :key="index + indexG"
                        >
                            <!-- WOMP (above) -->
                            <!-- eslint-disable-next-line vue/valid-v-for -->
                            <el-menu-item
                                v-show="authorizedForApp(link)"
                                :class="{ activeSubMenu: activeLink(link) }"
                                :index="index + indexG"
                            >
                                <span v-show="activeLink(link) && authorizedForApp(link)">
                                </span>
                                <router-link v-if="link.route" :to="'/' + link.route">
                                    {{ link.label }}
                                </router-link>
                                <a v-if="link.href" :href="link.href">{{ link.label }}</a>
                            </el-menu-item>
                        </template>
                    </el-menu-item-group>
                </el-sub-menu>
            </template>
        </el-menu>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';

export default {
    components: {},
    data() {
        return {
            // TODO: Change routes to a consistent pattern (i.e appname/create for create and appname/id for edit)
            sidebarLinks: {
                1: {
                    icon: 'icon-stacked-chart1',
                    label: 'Contextual Intelligence',
                    roles: [
                        'contextual-BETA-app',
                        'contextualintelligence_internal-user'
                    ],
                    trademark: true,
                    children: [
                        {
                            label: 'Media Context',
                            route: 'contextual',
                            role: 'contextual-BETA-app'
                        },
                        {
                            label: 'Consumer Context',
                            route: 'weather-triggers',
                            role: 'contextualintelligence_consumer-context'
                        }
                    ]
                },
                2: {
                    icon: 'icon-series-search1',
                    label: 'Audience Optics',
                    trademark: true,
                    roles: [
                        'audienceoptics_external-user',
                        'audienceoptics_internal-user'
                    ],
                    children: [
                        {
                            label: 'Advertiser Setup',
                            route: 'advertiser-manager',
                            role: 'audienceconnect_advertisers'
                        },
                        {
                            label: 'Tag Manager',
                            route: 'tag-manager',
                            role: 'audienceoptics_tagbuilder-app'
                        },
                        {
                            label: 'Seed Segment Builder',
                            route: 'seed-segment-builder',
                            role: 'audienceoptics_seedbuilder-app'
                        },
                        {
                            label: 'Advantage Segment Builder',
                            route: 'advantage-segment-builder',
                            role: 'audienceoptics_audiencebuilder-app'
                        }
                    ]
                },
                3: {
                    icon: 'icon-stacked-chart1',
                    label: 'AIM Insights',
                    trademark: true,
                    roles: ['aim_external-user', 'aim_internal-user'],
                    children: [
                        {
                            label: 'Query Builder',
                            route: 'reports',
                            role: 'aim_querybuilder-app'
                        }
                    ]
                },
                4: {
                    icon: 'icon-cog',
                    label: 'Admin',
                    roles: ['ats_admin-group'],
                    children: [
                        {
                            label: 'Lambdas',
                            route: 'admin',
                            role: 'ats_admin-group'
                        },
                        {
                            label: 'System Events',
                            route: 'system-events',
                            role: 'ats_admin-group'
                        }
                    ]
                }
            }
        };
    },
    watch: {
        groupMembership(n, o) {
            console.log('group membership updated', n, o);
        }
    },
    methods: {
        toggleSide() {
            this.sidebarOpen = !this.sidebarOpen;
        },
        authorizedForApp(link) {
            return this.groupMembership[link.role];
        },
        pillarActive(pillar) {
            const activeLinks = pillar.children.filter((link) => {
                return `${link.route}` === this.$route.path;
            });
            return activeLinks.length;
        },
        activeLink(link) {
            const url = this.$route.path;
            return RegExp(`^/${link.route}/?.*`).test(url);
        }
    },
    computed: {
        ...mapState(['sidebarVisible']),
        ...mapGetters({
            userGroups: 'user/USER_GROUPS',
            groupMembership: 'user/GROUP_MEMBERSHIP'
        }),
        sidebarOpen: {
            get() {
                return this.sidebarVisible;
            },
            set(v) {
                this.$store.commit('setSidebar', v);
            }
        },
        pillarState() {
            // const ug = this.userGroups;
            const g = this.groupMembership;
            const state = {};
            for (const key in this.sidebarLinks) {
                const pillar = this.sidebarLinks[key];
                if (!Object.prototype.hasOwnProperty.call(state, pillar.label)) {
                    state[pillar.label] = pillar.roles.some((role) => g[role]);
                }
            }
            return state;
        }
    }
};
</script>

<style lang="scss" scoped>
.el-menu {
    display: flex;
    flex-direction: column;
    order: -1;
    flex: 0 0 22em;
    position: relative;
    height: 100%;

    ul:last-child {
        flex-grow: 1;
    }
}

.el-menu:not(.el-menu--collapse) {
    width: 240px;
}

.el-menu--popup .el-menu-item-group {
    margin-top: 0;
}

.el-menu-item-group {
    margin-top: -13px;
}

.el-submenu .el-menu-item {
    height: 30px;
    line-height: 30px;

    a {
        color: #303133;
    }

    a:hover {
        text-decoration: none;
        color: #303133;
    }
}

.el-menu--collapse {
    text-align: center;
}

.el-menu-item a {
    text-decoration: none;
}
</style>
