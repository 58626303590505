<template>
    <header class="top">
        <el-row type="flex" class="nav m-0">
            <el-col :span="1" class="home-icon-section glyph">
                <span
                    @click="$router.push('/')"
                    class="main-home-icon icon-home"
                ></span>
            </el-col>
            <el-col :span="5" class="logo-container">
                <div class="text-hide logo"></div>
                <div class="activation-logo"></div>
            </el-col>
            <el-col :span="7" :offset="5" v-if="canDoThings()" class="top-search-wrapper">
                <top-search />
            </el-col>
            <el-col :span="6" v-if="canDoThings()">
                <el-menu mode="horizontal" :ellipsis="false">
                    <el-menu-item @click="$router.push('/alert-manager')" index="1">
                        <i class="icon-notifications"></i>
                        Alerts
                    </el-menu-item>
                    <el-sub-menu index="2">
                        <template #title>
                            <i class="icon-help"></i>
                            <span>Help</span>
                        </template>
                        <el-menu-item>
                            <a target="_blank" :href="linkOverride || helpLink">View wiki page</a>
                        </el-menu-item>

                        <!-- TODO: display this menu after the Jira problem with generating a ticket at runtime has been fixed. -->
                        <!-- <el-menu-item @click="reportIssue">
                            Report an issue
                        </el-menu-item> -->
                    </el-sub-menu>
                    <el-sub-menu index="3">
                        <template #title>
                            <i class="icon-person"></i>
                            {{ username }}
                        </template>
                        <el-menu-item v-if="isUserAdmin" @click="userManager" index="2-1">
                            User Manager
                        </el-menu-item>
                        <el-menu-item @click="logUserOut" index="2-1">
                            Logout
                        </el-menu-item>
                    </el-sub-menu>
                </el-menu>
            </el-col>
        </el-row>
        <jira-dialog
            :show-jira-dialog="state.showJiraDialog"
            @closeJiraDialog="closeJiraDialog"
        ></jira-dialog>
    </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TopSearch from '@/components/TopSearch';
import ValidatorMixin from '@/mixins/validator';
import canDoThings from '@/mixins/canDoThings';
import JiraDialog from '@/components/shared/JiraDialog';

const helpLinkMap = {
    // nav
    'alert-manager':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/587726863/Alerts',
    // optics
    'advantage-segment-builder':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/827654151/Advantage+Segment+Builder',
    'seed-segment-builder':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/1235648513/Seed+Segment+Builder',
    'segment-overlap':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/75273243/Segment+Overlap',
    'tag-manager':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/75273297/Tag+Manager',
    'tag-manager-create':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/75273297/Tag+Manager',
    'tag-manager-edit':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/75273297/Tag+Manager',
    'dan-tag-segment-builder':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/75273341/DAN+Tag+Segment+Builder',
    'dan-tag-segment-builder-create':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/75273341/DAN+Tag+Segment+Builder',
    'behavioral-segment-builder':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/547029051/Behavioral+Segment+Builder',
    // contextual intelligence
    'weather-triggers':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/1100972050/Weather+Triggers',
    // connect
    'advertiser-manager':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/81952769/Advertiser+Manager',
    'advertiser-manager-edit':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/81952769/Advertiser+Manager',
    'bidder-fees':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/255426575/Bidder%2BFees',
    'campaigns':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/142704877/Campaigns',
    'campaigns-edit':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/142704877/Campaigns',
    'campaigns-create':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/142704877/Campaigns',
    'campaign-display':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/142704877/Campaigns',
    // insights
    'audience-explorer':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/868515841/Audience+Explorer',
    'cars': 'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/330924070/Cars.com',
    'reports':
    'https://accordantmedia.atlassian.net/wiki/spaces/DOC/pages/563478530/Query+Builder'
};

export default {
    components: {
        TopSearch, JiraDialog
    },

    mixins: [ValidatorMixin, canDoThings],

    data() {
        return {
            searchInputText: null,
            contextMenu: null,
            validity: {},
            loading: false,
            submitSuccess: false,
            linkOverride: null,
            state: {
                showJiraDialog: false
            }
        };
    },

    computed: {
        ...mapGetters({
            username: 'user/USER_NAME',
            authCookie: 'user/AUTH_COOKIE',
            userGroups: 'user/USER_GROUPS',
            env: 'ENV',
            claims: 'user/USER_CLAIMS'
        }),
        helpLink() {
            const url = this.$route.path.split('/')[1];
            if (Object.keys(helpLinkMap).indexOf(url) > -1) {
                return helpLinkMap[url];
            } else {
                return null;
            }
        },
        isUserAdmin() {
            return this.userGroups.includes('ats_usersBETA-app');
        }

    },

    mounted() {
        this.setHelpOverrideEvent = (link) => link && link.length ? (this.linkOverride = link) : null;
        this.releaseHelpOverrideEvent = (link) => this.linkOverride === link ? (this.linkOverride = null) : null;

        this.emitter.on('helpLink-override', this.setHelpOverrideEvent);
        this.emitter.on('helpLink-releaseOverride', this.releaseHelpOverrideEvent);
    },

    beforeUnmount() {
        if (this.setHelpOverrideEvent) { this.emitter.off('helpLink-override', this.setHelpOverrideEvent); }
        if (this.releaseHelpOverrideEvent) { this.emitter.off('helpLink-releaseOverride', this.releaseHelpOverrideEvent); }
    },

    methods: {
        ...mapActions({
            logout: 'user/LOG_OUT'
        }),
        reportIssue() {
            this.state.showJiraDialog = true;
        },

        closeJiraDialog() {
            this.state.showJiraDialog = false;
        },
        async logoutLocal() {
            try {
                // Clear local session
                await this.logout(); // ATS artifacts
            } catch (err) {
                throw new Error(`local session could not be terminated. ${err}`);
            }
        },

        logoutRemote() {
            this.$auth.signOut();
        },

        async logUserOut() {
            try {
                // Read idToken before local session is cleared
                const idToken = await this.$auth.getIdToken();
                await this.logoutLocal();
                this.logoutRemote(idToken);
            } catch (err) {
                throw new Error(`Error logging user out. ${err}`);
            }
        },

        userManager() {
            this.$router.push('/user-manager');
        }
    }
};
</script>

<style lang="scss" scoped>
.el-menu--horizontal {
  display: flex;
  justify-content: flex-end;
  border-bottom: none;
  background-color: #05051e;

  a {
    font-size: 14px;
    color: #909399;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}
.el-menu-item.is-active {
  border-bottom: none;
  color: #909399;
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.dialog-jira {
  color: inherit;
}
.logo-container {
    display: flex;
    align-items: center;

    .logo {
        margin: 1em 0 0 1em;
    }

    .activation-logo {
        margin: 0 0 0 1.5em;
        flex: 1 1 auto;
    }
}
.top-search-wrapper {
    align-self: center;
}
</style>
